import React from 'react'

const Bridge: React.FC = () => {
  return (
    <>
      <div className="bridge-coming-soon">
        <div className="coming-soon-text">Coming soon</div>
      </div>
    </>
  )
}

export default Bridge
