import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from 'boltswap-ui/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme { }
}

const GlobalStyle = createGlobalStyle`  
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }

  .page-section-second {
    div:hover {
      transform: scale(1.02);
      transition: 0.5s all;
    }
  }

  .loading-indicator {
    width: 100%;
    position: absolute;
    height: inherit;
    justify-content: center;
    display: flex;
    background-color: #202020ed;
    
    span {
      color: #FFFFFF;
      font-size: 25px;
      font-weight: bold;
      display: block;
      text-align: center;
      margin-top: 25px;
      cursor: default;
    }
  }

  .bridge-coming-soon {
    height: 80vh;
    position: relative;

    .coming-soon-text {
      position: absolute;
      color: rgb(154, 106, 255);
      left: 0; 
      right: 0; 
      text-align: center;
      margin-left: auto; 
      margin-right: auto; 
      font-size: 40px;
      font-weight: bold;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .dKQEfU {
    margin-left: auto;
    margin-right: auto;
  }

  .fepHIR, .kPqiJB {
    border-bottom: none;
  }

  .socials {
    align-items: center;
    justify-content: center;
  }

  .docs {
    order: 3;
    color: white;
    text-align: center;
  }

  .tvl-string {
    color: #9A6AFF;
    display: block;
    font-size: 25px;
    font-weight: bold;
    margin-left: auto;
    
    .title-tvl {
      color: #d6b9f9;
      padding-right: 7px;
    }
  }

  .my-holdings-string {
    color: #9A6AFF;
    display: block;
    font-size: 25px;
    font-weight: bold;
    position: absolute;
    top: 125px;
    .title-tvl {
      color: #d6b9f9;
      padding-right: 7px;
    }
  }

  .harvest-card {
    .tvl-string {      
      display: block;
      margin-left: auto;
      text-align: right;
      margin-bottom: 16px;
    }
    .harvest-all {
      margin-left: auto;
    }
  }

  /* .hiOtqn *{
    font-family: Roboto;  
  } */


  .stats-a {
    height: 2600px;
    position: relative;

    .coming-soon-text {
      position: absolute;
      color: #D22C89;
      left: 0; 
      right: 0; 
      text-align: center;
      margin-left: auto; 
      margin-right: auto; 
      font-size: 40px;
      font-weight: bold;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .kpWzIH {
    font-size: 15.7px;
  }

  .imiWnV {
    margin-left: auto;
    margin-right: auto;
  }

  .bqVoIi {
    display: none;
  }


  .bPepfS {
    grid-template: initial;
  }



  .kkrQbD {
    align-items: center;
  }

  .kGFlVV {
    display: none;
  }

  .kaRICp, .EHRxJ, .lRfdj {
    &:before {
      border: none;
    }
  }

  .KmhKu {
    margin-left: auto;
    margin-right: auto;
  }

  .hCTQSm {
    text-align: center;
  }

  .jiWrzP {
    margin-left: auto;
    margin-right: auto;
  }

  .gNQUQW {
    font-size: 15px;
    font-weight: bold;
  }  


  .farm-icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -90px;
  }

  .moving-img {
    margin-left: auto;
    margin-right: auto;
  }

  .desktop-icon.footer-icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .locker-link-btn {
    border-radius: 16px;
    background: none;
    color: white;
    border: 1px solid #873edf;
  }
  .locker-link {
    max-width: 950px;
    width: 100%;
    margin-bottom: 20px;
    
  }

  .div-locker-text {
    background: #000;
    
    display: flex;
    flex-direction: column;
    max-width: 950px;
  }

  .black-div2 {
    background: #000
  }
  .black-div {
    background: #000;
    margin: 20px 24px;
  }

  .heading-style {
    margin-bottom: 3px;
    padding: 6px;
    background: #873edf;
    border-radius: 16px;
    text-align: center;
    font-size: 18px;
  }

  .full-content {
    background: #000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .left-side {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    justify-content: center;
    margin: 20px 24px;
  }

  .date-center {
    display: flex;
    justify-content: center;
    margin-right: 24px;
    margin-left: 24px;
  }


  .date-div {
    border-radius: 16px;
    border: 1px solid #372F47;
    max-width: 650px;
    width: 100%;
    background-color: 1d1d1d;
  }

  .date-time {
  max-width: 650px;
  width: 100%;
  text-overflow: ellipsis;
  font-weight: bold;
  background: #030918;
  
  height: 60px;
  outline: 0;
  border-radius: 0.25rem;
  font-size: 16px;
  padding: 0 16px;
  box-shadow: inset 0px 2px 2px -1px rgb(74 74 104 / 10%);
  z-index: 999 !important;
  color: #F4EEFF;
  border-radius: 16px;
  margin-left: auto;
  border: none;

  &:focus {
    box-shadow: 0px 0px 0px 1px #873edf, 0px 0px 0px 4px #873edf;
  }
}



  .max-btn {
    z-index: 999;
    position: absolute;
    right: 55px;
    top: 49%;
    background: none;
    color: white;
    border: 1px solid #873edf;
    border-radius: 16px;
    transition: 0.2s all;
    padding: 3px 6px;
    display: flex;
    justify-content: center;
    text-align: center;
    &:hover {
      cursor: pointer;
      background: #873edf;
    }
  }
  .me-btn {
    z-index: 999;
    position: absolute;
    width: 35px;
    right: 55px;
    top: 68%;
    background: none;
    color: white;
    border: 1px solid #873edf;
    border-radius: 16px;
    transition: 0.2s all;
    padding: 3px 6px;
    display: flex;
    justify-content: center;
    text-align: center;
    &:hover {
      cursor: pointer;
      background: #873edf;
    }
  }
  .locker-text {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }
  .black-div2 {
    background: #000
  }

  .lock-button {
    max-width: 650px;
    width: 100%;
    justify-content: center;


  }

  .search-locker {
    justify-content: center;
    position: relative;
    padding-left: 16px;
    padding-right: 35px;
    margin-bottom: 20px;
    max-width: 650px;
    width: 100%;
    font-weight: bold;
    font-size: 18px;
    border-radius: 16px;
    margin-left: auto;
    background: #030918;
    height: 60px;
    border: 1px solid #372F47;
    color: #F4EEFF;
    text-overflow: ellipsis;
    -webkit-appearance: textfield;
    *{
      font-weight: bold;
    }

    
    &:focus {
      outline: none;
      box-shadow: 0px 0px 0px 1px #873edf, 0px 0px 0px 4px #873edf;
    }

    ::placeholder {
    color: ${({ theme }) => theme.colors.textSubtle};
  }

  }

.locking-wrapper {
    margin-top: 20px;

    margin-right: 24px;
    margin-left: 24px;
    display: flex;
    flex-direction: column;
  }

  .search-button {
    width: 100%;
    max-width: 950px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;    
    margin-bottom: 20px;
  }

  .locker-search {

background: none;
display: flex;
flex-direction: row;
justify-content: space-between;

align-items: space-between;

}

  .search-locker-tab {
    justify-content: center;

    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    max-width: 950px;
    width: 100%;
    
    *{
      font-weight: bold;
    }
  }

  .locker-card {
    margin: 12px 24px !important;
    padding: 12px 24px !important;
    background: radial-gradient(circle,#873edf -150%,rgb(4,4,4) 100%);

}

.locker-card-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

.div-locker-text {
background: #000;

display: flex;
flex-direction: column;
max-width: 950px;
}

.locker-card-text {
margin-left: 24px;
margin-right: 24px;
margin-bottom: 20px;
margin-top: 24px;
background: black;
}

  @media screen and (max-width: 1330px) {

    .me-btn {
      top: 74%;
    }

    .max-btn {
      top: 54%;
    }
    .full-content {
      flex-direction: column;
    }

    .locker-text {
      flex-direction: column;
    }
    .locker-link {
      max-width: 750px;
    }
  }
    @media screen and (max-width: 680px) {

    .locker-card-div {
      flex-direction: row;
      justify-content: space-between;
    }


    .locker-search {
      flex-direction: column;
    }


  }



  @media screen and (max-width: 576px) {
    .farm-icon {
      margin-bottom: 0px;
    }
    .loading-indicator {
      padding-top: 31px;
    }
    .harvest-all {
      width: 100% !important;
      margin-bottom: 16px;
    }
    .header-bolt-price {
      img {
        display: none;
      }

      a::before {
        content: "Bolt";
        color: #873edf;
        font-weight: bold;
        text-transform: uppercase;
        margin-right: 8px;
      }
    }

    .harvest-card {
      display: flex;
      flex-direction: column;
      margin-top: 33px;
    }

    .tvl-string {
      order: 2;
      font-size: 18px;
      margin-right: auto;
      margin-left: 0px;
    }

    .my-holdings-string {
      top: 16px !important;
      left: 30px;
      font-size: 18px;      
    }

  }

  .swap-icon {
    margin-bottom: 15px;
  }

  .partners {    
    margin-top: 170px;
    .title {
      font-size: 40px;
      color: #F4EEFF;
      text-align: center;
      margin-bottom: 30px;
    }

    .partners-container {
      margin-top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;

      .partner {
        transition: 0.5s all;
        cursor: pointer;

        &:hover {
          transform: scale(1.05);
          filter: invert(0.3);
        }

        img {
          width: unset;
          height: 40px;
        }
      }
    }
  }
`

export default GlobalStyle
