import React from 'react'


const Stats: React.FC = () => {
    return (
        <>
            <div className='stats-a'>
                <iframe title='info' src='https://info.boltswap.tech/home' style={{ width: '100%', height: '100%' }} scrolling='no' />
            </div>
        </>
    )
}

export default Stats