import MoonSwapPairAbi from 'config/abi/MoonSwapPair.json'
import { ethers } from 'ethers'
import BigNumber from 'bignumber.js'

const provider = new ethers.providers.JsonRpcProvider('https://rpc.shiden.astar.network:8545')

export async function getSdnPrice() {
    const moonswapPair = new ethers.Contract(
        "0x5bb745Ab9Fa20E36b8e1a4fd1b14f08C5D372bB1",
        MoonSwapPairAbi,
        provider
    )
    const reserves = await moonswapPair.getReserves()
    const reserve0 = reserves.reserve0._hex
    const reserve0Hex = new BigNumber(reserve0).c[0] * 100

    const reserve1 = reserves.reserve1._hex
    const reserve1Hex = new BigNumber(reserve1).c[0]

    const finalPrice = reserve1Hex / reserve0Hex

    return finalPrice
}