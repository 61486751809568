/* eslint-disable */

import { useMoonLockerContractNoAddress } from "hooks/useContract";
import { useCallback } from "react";


export default function useLocker() {

    const contract = useMoonLockerContractNoAddress()

    const withdrawTokens = useCallback(
        async (id: string) => {
            try {
                return await contract?.withdrawTokens(id)
            } catch (e) {
                console.error(e)
                return e
            }
        }, [contract]
    )

    const getLockersByTokenAddress = useCallback(
        async (token: string) => {
            try {
                const lockersIds = await contract?.getDepositsByTokenAddress(token)
                const result = []
                if (lockersIds.length > 0) {
                    for (const id of lockersIds) {
                        const lockerInfo = await contract?.lockedToken(id.toString())
                        result.push({ id, ...lockerInfo })
                    }
                }
                return result
            } catch (e) {
                console.error(e)
                return e
            }
        }, [contract]
    )
    return { withdrawTokens, getLockersByTokenAddress }
}