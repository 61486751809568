import { MenuItemsType, DropdownMenuItemType } from 'boltswap-ui'
import { ContextApi } from 'contexts/Localization/types'
import { nftsBaseUrl } from 'views/Nft/market/constants'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  {
    label: t('Swap'),
    icon: 'boltswap-nav-swap',
    href: '/swap',
    showItemsOnMobile: false,
    items: [
    ],
  },
  {
    label: t('Liquidity'),
    href: '/liquidity',
    icon: 'boltswap-nav-liquidity',
    showItemsOnMobile: false,
    items: [
    ],
  },
  {
    label: t('Farms'),
    href: '/farms',
    icon: 'boltswap-nav-farms',
    showItemsOnMobile: false,
    items: [
    ],
  },
  {
    label: t('Locker'),
    href: '/locker',
    icon: 'boltswap-nav-locker',
    showItemsOnMobile: false,
    items: [
    ],
  },
  {
    label: t('Bridge'),
    href: '/bridge',
    icon: 'boltswap-nav-bridge',
    showItemsOnMobile: false,
    items: [
    ],
  },
  {
    label: t('Stats'),
    href: '/stats',
    icon: '/boltswap-nav-stats',
    showItemsOnMobile: false,
    items: []
  },
]

export default config
