/* eslint-disable */
import React, { useState, useCallback } from 'react'
import { Flex, Button, Card, Text, Heading } from 'boltswap-ui'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import moment from 'moment'
import styled from 'styled-components'
import useToast from 'hooks/useToast'
import useLocker from 'state/locker/hooks'
import { getAddress } from 'ethers/lib/utils'
import { isAddress } from 'utils'
import { useToken } from 'hooks/Tokens'
import { CurrencyAmount } from 'boltswap-sdk'
import { useTransactionAdder } from 'state/transactions/hooks'
import { AppHeader } from 'components/App'
import AppBody from './Components/AppBody'
import SearchInput from './Components/SearchInput'


function Lockersearch() {

    const { account } = useActiveWeb3React()
    const [tokenAddress, setTokenAddress] = useState(undefined)
    const token = useToken(isAddress(tokenAddress) ? tokenAddress : undefined)
    const [pendingTx, setPendingTx] = useState(false)
    const addTransaction = useTransactionAdder()
    const [lockers, setLockers] = useState([])
    const [update, setupdate] = useState(false)
    const { toastSuccess, toastError } = useToast()

    const lockerContract = useLocker()

    // useEffect(() => {
    //     if (isAddress(tokenAddress)) {
    //         lockerContract.getLockersByTokenAddress(tokenAddress).then((r: any) => {
    //             if (r.length > 0) {
    //                 setLockers(r.filter((x) => x.withdrawn === false))
    //             }
    //         })
    //     }
    // }, [tokenAddress, lockerContract])

    async function searchTokens() {
        try {
            setupdate(true)
            if (isAddress(tokenAddress)) {
                toastSuccess('Searching for tokens')
                setPendingTx(true)
                const waittx = await lockerContract.getLockersByTokenAddress(tokenAddress).then((r: any) => {
                    if (r.length > 0) {
                        setLockers(r.filter((x) => x.withdrawn === false))
                    }
                })
                const awaittx = await waittx
                setPendingTx(false)
            }
            setupdate(false)
        }
        catch (err) { }
        finally { setupdate(false) }

    }

    const handleWithdraw = useCallback(
        async (id) => {
            setPendingTx(true)

            try {
                const tx = await lockerContract.withdrawTokens(id)
                addTransaction(tx, { summary: `Withdraw from locker ${id}` })
                const awaittx = await tx.wait()
                toastSuccess('Successfully withdrawn locked tokens')
                setPendingTx(false)
            } catch (error) {
                console.error(error)
            }
        },
        [addTransaction, lockerContract, toastSuccess]
    )


    console.log('lockers')
    console.log(lockers)

    const handleInputAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTokenAddress(event.target.value)
    }

    return (
        <AppBody >
            <div className='black-div2'>
                <AppHeader title='Search lockers' subtitle='Insert the address of locked tokens and search for them ' noConfig />
                <Flex className="locking-wrapper" style={{ justifyContent: 'center' }}>
                    <SearchInput className="search-locker-tab" onChange={handleInputAddress} placeholder="Search for locked tokens" />
                    <Button
                        className="search-button"
                        onClick={searchTokens}
                        disabled={update}
                    >Search</Button>
                </Flex>

                {lockers.map((locker: any, index) => {
                    return (
                        <div key={index} className='locker-card'>
                            <div className="locker-search">
                                <div className='locker-card-div'>
                                    <Heading>Locked tokens</Heading>
                                    <Text className="smaller-text">{token?.name}({token?.symbol})</Text>
                                </div>
                                <div className='locker-card-div'>
                                    <Heading>Amount locked</Heading>
                                    <Text className="smaller-text">{CurrencyAmount.ether(locker?.amount).toSignificant(6)} LP</Text>
                                </div>
                                <div className='locker-card-div'>
                                    <Heading>Time until release</Heading>
                                    <Text className="smaller-text">
                                        {moment.unix(locker?.unlockTimestamp.toString()).fromNow()}
                                    </Text>
                                </div>
                                <Button
                                    className="small-btn"
                                    onClick={() => handleWithdraw(locker?.id)}
                                    disabled={
                                        moment.unix(locker?.unlockTimestamp.toString()).isAfter(new Date()) ||
                                        !account ||
                                        (account && getAddress(account) !== getAddress(locker?.withdrawer)) ||
                                        pendingTx
                                    }
                                >
                                    Unlock
                                </Button>
                            </div>
                        </div>
                    )
                })}
            </div>
        </AppBody>
    )
}

export default Lockersearch
