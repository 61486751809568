import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    pid: 1,
    lpSymbol: 'BOLT-SDN LP',
    lpAddresses: {
      336: '0x8089bC747bb9a096C2c8D5f6459136bc55D2681A',
      4: '0x4F35dF4D73833Fb0B81cF38a0d7c02E244046C8D',
    },
    token: serializedTokens.bolt,
    quoteToken: serializedTokens.wsdn,
  },
  {
    pid: 2,
    lpSymbol: 'BOLT-USDC LP',
    lpAddresses: {
      336: '0x82ad55fE3fE3778a751Ff088dE3a385E75BAAc95',
      4: '0x9cbcC7479D492868FB8b459377c4c7c420227847',
    },
    token: serializedTokens.bolt,
    quoteToken: serializedTokens.usdc,
  },
  {
    pid: 15,
    lpSymbol: 'BOLT',
    lpAddresses: {
      336: '0xA96Ed945e4856aa27269b59A58b36be9F3A82204',
      4: '0x4F35dF4D73833Fb0B81cF38a0d7c02E244046C8D',
    },
    token: serializedTokens.bolt,
    quoteToken: serializedTokens.bolt,
  },
  {
    pid: 3,
    lpSymbol: 'SDN-USDC',
    lpAddresses: {
      336: '0x5bb745Ab9Fa20E36b8e1a4fd1b14f08C5D372bB1',
      4: '0x4F35dF4D73833Fb0B81cF38a0d7c02E244046C8D',
    },
    token: serializedTokens.wsdn,
    quoteToken: serializedTokens.usdc,
  },
  {
    pid: 4,
    lpSymbol: 'SDN-MOVR',
    lpAddresses: {
      336: '0x31181f9D8B1CBa45Eb8d4843ECf300d7FD584Cd6',
      4: '0x4F35dF4D73833Fb0B81cF38a0d7c02E244046C8D',
    },
    token: serializedTokens.wsdn,
    quoteToken: serializedTokens.movr,
  },
  {
    pid: 5,
    lpSymbol: 'DAI-USDC',
    lpAddresses: {
      336: '0x488fef2d72FFB811FC952719C2468Ac50CEf914E',
      4: '0x4F35dF4D73833Fb0B81cF38a0d7c02E244046C8D',
    },
    token: serializedTokens.dai,
    quoteToken: serializedTokens.usdc,
  },
  {
    pid: 6,
    lpSymbol: 'BUSD-USDC',
    lpAddresses: {
      336: '0x0334aFB968944FcF18d3781F0d988C938efF5600',
      4: '0x4F35dF4D73833Fb0B81cF38a0d7c02E244046C8D',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.usdc,
  },
  {
    pid: 7,
    lpSymbol: 'USDT-USDC',
    lpAddresses: {
      336: '0x184A0A9cbc1AFEa4AAFF68072dbd7E1819916BEE',
      4: '0x4F35dF4D73833Fb0B81cF38a0d7c02E244046C8D',
    },
    token: serializedTokens.usdt,
    quoteToken: serializedTokens.usdc,
  },
  {
    pid: 8,
    lpSymbol: 'MAI-USDC',
    lpAddresses: {
      336: '0x19dBf84f8dE7A806042dBC46CA2D604e3c393db5',
      4: '0x4F35dF4D73833Fb0B81cF38a0d7c02E244046C8D',
    },
    token: serializedTokens.mai,
    quoteToken: serializedTokens.usdc,
  },
  {
    pid: 9,
    lpSymbol: 'ETH-USDC',
    lpAddresses: {
      336: '0x78778b5d5229f8076e1796A73fBE59687a37D868',
      4: '0x4F35dF4D73833Fb0B81cF38a0d7c02E244046C8D',
    },
    token: serializedTokens.weth,
    quoteToken: serializedTokens.usdc,
  },
  {
    pid: 10,
    lpSymbol: 'BNB-BUSD',
    lpAddresses: {
      336: '0x43ab3C683FC2dD450CC77EEd1E4E286fAa7F57eA',
      4: '0x4F35dF4D73833Fb0B81cF38a0d7c02E244046C8D',
    },
    token: serializedTokens.bnbNotSdn,
    quoteToken: serializedTokens.busd,
  },
  {
    pid: 11,
    lpSymbol: 'MATIC-SDN',
    lpAddresses: {
      336: '0x97b66BB0C30d6B24e97579bCf0e0893EA6e523C9',
      4: '0x4F35dF4D73833Fb0B81cF38a0d7c02E244046C8D',
    },
    token: serializedTokens.matic,
    quoteToken: serializedTokens.wsdn,
  },
  {
    pid: 12,
    lpSymbol: 'AVAX-SDN',
    lpAddresses: {
      336: '0x33Bd3E16123403E3095347afA934123eA487C462',
      4: '0x4F35dF4D73833Fb0B81cF38a0d7c02E244046C8D',
    },
    token: serializedTokens.avax,
    quoteToken: serializedTokens.wsdn,
  },
  {
    pid: 13,
    lpSymbol: 'FTM-SDN',
    lpAddresses: {
      336: '0x5aD975B7eA019D04fDd7Cd91C3ef89988DDeFc8e',
      4: '0x4F35dF4D73833Fb0B81cF38a0d7c02E244046C8D',
    },
    token: serializedTokens.ftm,
    quoteToken: serializedTokens.wsdn,
  },
  {
    pid: 14,
    lpSymbol: 'RELAY-SDN',
    lpAddresses: {
      336: '0xA00D468bAE62d70f8150B6f9760736FBF8048BB8',
      4: '0x4F35dF4D73833Fb0B81cF38a0d7c02E244046C8D',
    },
    token: serializedTokens.relay,
    quoteToken: serializedTokens.wsdn,
  },

]

export default farms
