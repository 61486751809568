import BigNumber from 'bignumber.js'
import { BIG_ONE, BIG_ZERO } from 'utils/bigNumber'
import { filterFarmsByQuoteToken } from 'utils/farmsPriceHelpers'
import { Prices, SerializedFarm } from 'state/types'
import tokens from 'config/constants/tokens'
import { getSdnPrice } from './prices'


const getFarmFromTokenSymbol = (
  farms: SerializedFarm[],
  tokenSymbol: string,
  preferredQuoteTokens?: string[],
): SerializedFarm => {
  const farmsWithTokenSymbol = farms.filter((farm) => farm.token.symbol === tokenSymbol)
  const filteredFarm = filterFarmsByQuoteToken(farmsWithTokenSymbol, preferredQuoteTokens)
  return filteredFarm
}

const getFarmBaseTokenPrice = (
  farm: SerializedFarm,
  quoteTokenFarm: SerializedFarm,
  bnbPriceBusd: BigNumber,

  bolt: BigNumber
): BigNumber => {
  const hasTokenPriceVsQuote = Boolean(farm.tokenPriceVsQuote)

  const stableTokens = ["USDT", "USDC", "DAI", "MAI", "BUSD"]

  if (farm.token.symbol === 'BOLT') {
    return bolt
  }
  if (farm.token.symbol === 'RELAY') {    
    /* eslint-disable */
    return BIG_ONE
  }
  if (farm.token.symbol === 'FTM') {
    /* eslint-disable */
    return BIG_ONE
  }
  if (farm.token.symbol === 'AVAX') {
    /* eslint-disable */
    return BIG_ONE
  }
  if (farm.token.symbol === 'MATIC') {
    /* eslint-disable */
    return BIG_ONE
  }
  if (farm.token.symbol === 'BNB') {
    /* eslint-disable */
    return BIG_ONE
  }
  if (farm.token.symbol === 'WETH') {
    /* eslint-disable */
    return BIG_ONE
  }
  if (farm.token.symbol === 'SDN') {
    /* eslint-disable */
    return bnbPriceBusd
  }
  if (farm.quoteToken.symbol === 'DAI') {
    return BIG_ONE
  }
  if (farm.quoteToken.symbol === 'MAI') {
    return BIG_ONE
  }

  if (stableTokens.includes(farm.token.symbol)) {
    return BIG_ONE
  }

  if (farm.quoteToken.symbol === tokens.busd.symbol) {
    return hasTokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (farm.quoteToken.symbol === tokens.weth.symbol) {
    return hasTokenPriceVsQuote ? bnbPriceBusd.times(farm.tokenPriceVsQuote) : BIG_ZERO
  }

  // We can only calculate profits without a quoteTokenFarm for BUSD/BNB farms
  if (!quoteTokenFarm) {
    return BIG_ZERO
  }

  // Possible alternative farm quoteTokens:
  // UST (i.e. MIR-UST), pBTC (i.e. PNT-pBTC), BTCB (i.e. bBADGER-BTCB), ETH (i.e. SUSHI-ETH)
  // If the farm's quote token isn't BUSD or wsdn, we then use the quote token, of the original farm's quote token
  // i.e. for farm PNT - pBTC we use the pBTC farm's quote token - BNB, (pBTC - BNB)
  // from the BNB - pBTC price, we can calculate the PNT - BUSD price
  if (quoteTokenFarm.quoteToken.symbol === tokens.weth.symbol) {
    const quoteTokenInBusd = bnbPriceBusd.times(quoteTokenFarm.tokenPriceVsQuote)
    return hasTokenPriceVsQuote && quoteTokenInBusd
      ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInBusd)
      : BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === tokens.busd.symbol) {
    const quoteTokenInBusd = quoteTokenFarm.tokenPriceVsQuote
    return hasTokenPriceVsQuote && quoteTokenInBusd
      ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInBusd)
      : BIG_ZERO
  }  

  // Catch in case token does not have immediate or once-removed BUSD/wsdn quoteToken
  return BIG_ZERO
}

// export const fetchPrices = async () => {
//   const responseAPI = await fetch("https://api.boltswap.tech/api/prices")
//   const PRICES = await responseAPI.json();
//   return PRICES
// }

const getFarmQuoteTokenPrice = (
  farm: SerializedFarm,
  quoteTokenFarm: SerializedFarm,
  bnbPriceBusd: BigNumber,

  bolt: BigNumber,
): BigNumber => {


  if (farm.quoteToken.symbol === 'BOLT') {    
    return bolt
  }

  if (farm.quoteToken.symbol === 'SDN') {    
    return bnbPriceBusd
  }

  if (farm.quoteToken.symbol === 'USDT') {    
    return BIG_ONE
  }

  if (farm.quoteToken.symbol === 'MOVR') {
    /* eslint-disable */
    return BIG_ONE
  }

  if (farm.quoteToken.symbol === 'MATIC') {
    /* eslint-disable */
    return BIG_ONE
  }

  if (farm.quoteToken.symbol === 'BUSD') {
    return BIG_ONE
  }
  if (farm.quoteToken.symbol === 'USDC') {
    return BIG_ONE
  }

  if (farm.quoteToken.symbol === 'WSDN') {
    return bnbPriceBusd
  }
  if (farm.quoteToken.symbol === 'MOVR') {
    return BIG_ONE
  }
  if (farm.quoteToken.symbol === 'DAI') {
    return BIG_ONE
  }
  if (farm.quoteToken.symbol === 'MAI') {
    return BIG_ONE
  }

  if (!quoteTokenFarm) {
    return BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === 'WSDN') {
    return quoteTokenFarm.tokenPriceVsQuote ? bnbPriceBusd.times(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === 'BUSD') {
    return quoteTokenFarm.tokenPriceVsQuote ? new BigNumber(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
  }
  return BIG_ZERO
}

const fetchFarmsPrices = async (farms: SerializedFarm[]) => {
  const sdnPrice = await getSdnPrice()    
  const bnbPriceBusd = new BigNumber(sdnPrice); 
  const boltSdnFarm = farms.find((farm) => farm.pid === 1)
  const boltPrice = new BigNumber(boltSdnFarm.lpTotalInQuoteToken).div(2).div(boltSdnFarm.tokenAmountTotal).times(sdnPrice)
  const farmsWithPrices = farms.map((farm) => {

    const quoteTokenFarm = getFarmFromTokenSymbol(farms, farm.quoteToken.symbol)    
    const tokenPriceBusd = getFarmBaseTokenPrice(farm, quoteTokenFarm, bnbPriceBusd, boltPrice)
    const quoteTokenPriceBusd = getFarmQuoteTokenPrice(farm, quoteTokenFarm, bnbPriceBusd, boltPrice)

    return {
      ...farm,
      tokenPriceBusd: tokenPriceBusd.toJSON(),
      quoteTokenPriceBusd: quoteTokenPriceBusd.toJSON(),
    }
  })

  console.log(farmsWithPrices)

  return farmsWithPrices
}

export default fetchFarmsPrices
